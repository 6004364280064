// src/components/nav/ComicHeader.jsx
import React from 'react';
import { BaseHeader } from './BaseHeader';

export const ComicHeader = ({ comic }) => {
  return (
    <BaseHeader
      // title={comic.title}
      // subtitle={`by ${comic.author}`}
      backUrl="/"
      backLabel="Back to Comics"
     
    />
  );
};