// src/components/comic/ComicListItem.jsx
import React from 'react';
import { Link } from 'react-router-dom';

export const ComicListItem = ({ comic }) => (
  <Link 
    to={`/comic/${comic.slug}`}
    className="flex gap-4 p-4 bg-gray-800/50 rounded-xl group"
  >
    <div className="w-24 h-24 flex-shrink-0 rounded-lg overflow-hidden">
      <img
        src={`/comics/${comic.slug}/thumbnail.jpg`}
        alt={comic.title}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="flex-grow">
      <h2 className="font-medium text-lg">{comic.title}</h2>
      <p className="text-gray-400">{comic.author}</p>
      <div className="mt-2 flex gap-2 flex-wrap">
        {comic.genre.map((g) => (
          <span key={g} className="px-2 py-1 bg-gray-700/50 rounded-full text-sm">
            {g}
          </span>
        ))}
      </div>
    </div>
  </Link>
);