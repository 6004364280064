// src/components/reader/ContinuousReader.jsx
import React from 'react';

export const ContinuousReader = ({ images }) => {
  const containerRef = React.useRef(null);

  return (
    <div 
      ref={containerRef}
      className="max-w-5xl mx-auto space-y-0"
    >
      {images.map((image, index) => (
        <div 
          key={index}
          className="relative min-h-[200px]"
        >
          {image.status === 'pending' || image.status === 'loading' ? (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800/50">
              <div className="text-gray-400">Loading...</div>
            </div>
          ) : image.status === 'error' ? (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800/50">
              <div className="text-red-400">Failed to load image</div>
            </div>
          ) : (
            <img
              src={image.src}
              alt={`Page ${index + 1}`}
              className="w-full h-auto"
              loading={index === 0 ? "eager" : "lazy"}
            />
          )}
        </div>
      ))}
    </div>
  );
};