// src/pages/HomePage.jsx
import React from 'react';
import { HomeHeader } from '../components/nav/HomeHeader';
import { ComicCard } from '../components/comic/ComicCard';
import { ComicListItem } from '../components/comic/ComicListItem';

export const HomePage = () => {
  const [comics, setComics] = React.useState([]);

  React.useEffect(() => {
    const loadComics = async () => {
      try {
        const data = await fetch('/comics/master_info.json').then(r => r.json());
        setComics(data.comics);
      } catch (error) {
        console.error('Failed to load comics:', error);
      }
    };
    loadComics();
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <HomeHeader />
      
      <main className="container mx-auto px-4 pt-20">
        {/* Mobile List View */}
        <div className="space-y-4 md:hidden">
          {comics.map((comic) => (
            <ComicListItem key={comic.slug} comic={comic} />
          ))}
        </div>

        {/* Desktop Grid View */}
        <div className="hidden md:grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6">
          {comics.map((comic) => (
            <ComicCard key={comic.slug} comic={comic} />
          ))}
        </div>
      </main>
    </div>
  );
};