// src/pages/ComicDetail.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { BookOpen, Clock } from 'lucide-react';
import { readingProgress } from '../utils/storage';
import { ComicHeader } from '../components/nav/ComicHeader';

const ChapterCard = ({ comicSlug, chapter, index, isRead }) => (
    <Link
        to={`/comic/${comicSlug}/chapter/${index}`}
        className={`block p-4 bg-gray-800/50 rounded-lg hover:bg-gray-700/50 transition-colors
      ${isRead ? 'opacity-60' : ''}`}
    >
        <div className="flex items-center justify-between">
            <div>
                <h3 className="font-medium">{chapter.title}</h3>
                {chapter.notes && (
                    <p className="text-sm text-gray-400 mt-1">{chapter.notes}</p>
                )}
            </div>
            <div className="flex items-center gap-2">
                {isRead && (
                    <div className="flex items-center gap-1 text-sm text-gray-400">
                        <BookOpen className="w-4 h-4" />
                        <span>Read</span>
                    </div>
                )}
            </div>
        </div>
    </Link>
);

export const ComicDetail = () => {
    const { slug } = useParams();
    const [comic, setComic] = useState(null);
    const [readChapters, setReadChapters] = useState({});

    useEffect(() => {
        const loadComic = async () => {
            try {
                const response = await fetch(`/comics/${slug}/info.json`);
                const data = await response.json();
                setComic(data);

                // Load read status from localStorage
                const progress = readingProgress.getComicProgress(slug);
                setReadChapters(progress);
            } catch (error) {
                console.error('Failed to load comic:', error);
            }
        };

        loadComic();
    }, [slug]);

    if (!comic) return null;

    return (
        <div className="min-h-screen bg-gray-900 text-gray-100">
            {/* Dark gradient overlay behind header */}
            <div className="fixed top-0 inset-x-0 h-16 bg-gradient-to-b from-gray-900 to-transparent z-40" />

            <ComicHeader comic={comic} />

            {/* Hero Banner */}
            <div className="relative h-64 md:h-96 overflow-hidden mt-16">
                {comic.images.banner_bg && (
                    <img
                        src={comic.images.banner_bg}
                        alt=""
                        className="absolute inset-0 w-full h-full object-cover opacity-50"
                    />
                )}
                {comic.images.banner_fg && (
                    <img
                        src={comic.images.banner_fg}
                        alt={comic.title}
                        className="absolute inset-0 w-full h-full object-contain"
                    />
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900" />
            </div>

            {/* Content */}
            <div className="container mx-auto px-4 -mt-32 relative">
                {/* <Link 
          to="/"
          className="inline-flex items-center gap-2 text-gray-300 hover:text-white mb-4"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Comics
        </Link> */}

                <div className="flex flex-col md:flex-row gap-8">
                    {/* Metadata */}
                    <div className="md:w-1/3">
                        <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6">
                            <h1 className="text-2xl font-bold mb-2">{comic.title}</h1>
                            <p className="text-gray-400 mb-4">by {comic.author}</p>
                            <p className="text-gray-400 mb-4"> <a href={comic.source} rel="noreferrer" target="_blank"><i>Source Link</i></a></p>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {comic.genre.map((g) => (
                                    <span
                                        key={g}
                                        className="px-3 py-1 bg-gray-700/50 rounded-full text-sm"
                                    >
                                        {g}
                                    </span>
                                ))}
                                {comic.tags?.map((tag) => (
                                    <span
                                        key={tag}
                                        className="px-3 py-1 bg-gray-700/30 rounded-full text-sm text-gray-300"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>

                            <p className="text-gray-300 mb-4">{comic.summary}</p>

                            <div className="space-y-2 text-sm text-gray-400">
                                <div className="flex items-center gap-2">
                                    <Clock className="w-4 h-4" />
                                    <span>Status: {comic.status}</span>
                                </div>
                                {comic.schedule && (
                                    <div>
                                        Updates: {comic.schedule.frequency} on {comic.schedule.day}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Chapters */}
                    <div className="md:w-2/3">
                        <div className="space-y-4">
                            {comic.type === 'single' ? (
                                <ChapterCard
                                    comicSlug={slug}
                                    chapter={{ title: `Read: ${comic.title}` }}
                                    index="1"
                                    isRead={readChapters["1"]}
                                />
                            ) : (
                                Object.entries(comic.chapters || {}).map(([index, chapter]) => (
                                    <ChapterCard
                                        key={index}
                                        comicSlug={slug}
                                        chapter={chapter}
                                        index={index}
                                        isRead={readChapters[index]}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComicDetail;