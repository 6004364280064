// src/components/reader/ReaderHeader.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowUp } from 'lucide-react';

export const ReaderHeader = ({ comic, chapter, onToggleMenu }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Show scroll-to-top only when we've scrolled down
  const [showScrollTop, setShowScrollTop] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="fixed top-0 inset-x-0 bg-gray-800/50 backdrop-blur-sm z-50">
      <div className="container mx-auto px-4 h-16 flex items-center gap-4">
        <Link 
          to={`/comic/${comic.slug}`}
          className="flex items-center gap-2 text-gray-300 hover:text-white"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="sr-only md:not-sr-only"></span>
        </Link>

        <div className="flex-1 truncate">
          <h1 className="text-lg font-medium truncate">{comic.title}</h1>
          <p className="text-sm text-gray-400 truncate">
            {chapter.title}
          </p>
        </div>

        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="p-2 hover:bg-gray-700/50 rounded-lg"
            aria-label="Scroll to top"
          >
            <ArrowUp className="w-5 h-5" />
          </button>
        )}

        {/* <button
          onClick={onToggleMenu}
          className="p-2 hover:bg-gray-700/50 rounded-lg"
          aria-label="Open menu"
        >
          <Menu className="w-5 h-5" />
        </button> */}
      </div>
    </header>
  );
};