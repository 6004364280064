// src/components/nav/HomeHeader.jsx
import React from 'react';
import { BaseHeader } from './BaseHeader';

export const HomeHeader = () => {
  return (
    <BaseHeader
      title="Webcomic Reader"
      showBackArrow={false}
      // rightElements={
      //   <>
      //     <button
      //       className="p-2 hover:bg-gray-700/50 rounded-lg"
      //       aria-label="Library"
      //     >
      //       <BookOpen className="w-5 h-5" />
      //     </button>
      //     <button
      //       className="p-2 hover:bg-gray-700/50 rounded-lg"
      //       aria-label="Settings"
      //     >
      //       <Settings className="w-5 h-5" />
      //     </button>
      //   </>
      // }
    />
  );
};