// src/pages/ChapterReader.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ContinuousReader } from '../components/reader/ContinuousReader';
import { PageReader } from '../components/reader/PageReader';
import { ReaderHeader } from '../components/reader/ReaderHeader';
import { ReaderFooter } from '../components/reader/ReaderFooter';
import { readingProgress } from '../utils/storage';

// Image loading states for tracking individual image status
const LOADING_STATUS = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

// Generate image paths based on index
const generateImagePath = (basePath, index) => {
  const paddedIndex = String(index + 1).padStart(3, '0');
  return `${basePath}/${paddedIndex}.jpg`;
};

export const ChapterReader = () => {
  const { slug, chapterId } = useParams();
  const navigate = useNavigate();
  const [comic, setComic] = useState(null);
  const [chapterPath, setChapterPath] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [imageStatuses, setImageStatuses] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // eslint-disable-next-line
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  // Load comic metadata
  useEffect(() => {
    const loadComic = async () => {
      try {
        const response = await fetch(`/comics/${slug}/info.json`);
        const data = await response.json();
        setComic(data);

        // Determine chapter path
        const path = data.type === 'single' 
          ? `/comics/${slug}/chapters/1`
          : `/comics/${slug}/chapters/${data.chapters[chapterId].slug || chapterId}`;
        
        setChapterPath(path);
        
        // Mark chapter as read
        readingProgress.markChapterAsRead(slug, chapterId);

        // Reset scroll position when changing chapters
        window.scrollTo(0, 0);
      } catch (error) {
        console.error('Failed to load comic:', error);
      }
    };

    loadComic();
  }, [slug, chapterId]);

  // Preload and verify images
  useEffect(() => {
    if (!chapterPath) return;

    const verifyImage = (index) => {
      return new Promise((resolve) => {
        const img = new Image();
        const imgPath = generateImagePath(chapterPath, index);
        
        setImageStatuses(prev => ({
          ...prev,
          [index]: LOADING_STATUS.LOADING
        }));

        img.onload = () => {
          setImageStatuses(prev => ({
            ...prev,
            [index]: LOADING_STATUS.LOADED
          }));
          resolve(true);
        };

        img.onerror = () => {
          setImageStatuses(prev => ({
            ...prev,
            [index]: LOADING_STATUS.ERROR
          }));
          resolve(false);
        };

        img.src = imgPath;
      });
    };

    const loadImages = async () => {
      // Start with first 5 images
      const initialBatch = Array.from({ length: 5 }, (_, i) => verifyImage(i));
      const results = await Promise.all(initialBatch);
      const validPages = results.filter(Boolean).length;
      
      if (validPages > 0) {
        // If we found pages, continue checking until we find an invalid one
        let currentIndex = 5;
        let consecutiveErrors = 0;
        
        // Keep checking until we hit 3 consecutive missing images
        // This allows for occasional gaps in numbering
        while (consecutiveErrors < 3) {
          const exists = await verifyImage(currentIndex);
          if (exists) {
            consecutiveErrors = 0;
          } else {
            consecutiveErrors++;
          }
          currentIndex++;
        }
        
        // Set total pages to last valid image
        setTotalPages(currentIndex - 3);
        setIsLoadingComplete(true);
      } else {
        setIsLoadingComplete(true);
      }
    };

    loadImages();
  }, [chapterPath]);

  // Handle chapter navigation
  const navigateToChapter = (chapterId) => {
    if (chapterId) {
      navigate(`/comic/${slug}/chapter/${chapterId}`);
      window.scrollTo(0, 0);
    }
  };

  // Early render of UI while images load
  if (!comic) return null;

  // Find previous and next chapters
  const chapterIds = Object.keys(comic.chapters || {}).sort((a, b) => Number(a) - Number(b));
  const currentIndex = chapterIds.indexOf(chapterId);
  const previousChapter = currentIndex > 0 ? chapterIds[currentIndex - 1] : null;
  const nextChapter = currentIndex < chapterIds.length - 1 ? chapterIds[currentIndex + 1] : null;

  // Get current chapter
  const chapter = comic.type === 'single' 
    ? { title: comic.title } 
    : comic.chapters[chapterId];

  // Generate array of valid image paths
  const images = Array.from({ length: totalPages }, (_, i) => ({
    src: generateImagePath(chapterPath, i),
    status: imageStatuses[i] || LOADING_STATUS.PENDING
  }));

  const ReaderComponent = comic.layout === 'continuous' ? ContinuousReader : PageReader;

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <ReaderHeader 
        comic={comic}
        chapter={chapter}
        onToggleMenu={() => setIsMenuOpen(!isMenuOpen)}
      />

      <main className="pt-16 pb-16">
        <ReaderComponent 
          images={images}
          onPageChange={(page) => {
            // Preload next few images when page changes
            const preloadCount = 3;
            const startIdx = page + 1;
            for (let i = 0; i < preloadCount; i++) {
              const idx = startIdx + i;
              if (idx < totalPages && imageStatuses[idx] === LOADING_STATUS.PENDING) {
                const img = new Image();
                img.src = generateImagePath(chapterPath, idx);
              }
            }
          }}
        />
      </main>

      <ReaderFooter
        comic={comic}
        currentChapter={chapterId}
        previousChapter={previousChapter}
        nextChapter={nextChapter}
        onPreviousChapter={() => navigateToChapter(previousChapter)}
        onNextChapter={() => navigateToChapter(nextChapter)}
      />
    </div>
  );
};