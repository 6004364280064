// src/components/nav/BaseHeader.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export const BaseHeader = ({ 
  title, 
  subtitle, 
  backUrl, 
  backLabel = "Back",
  rightElements,
  showBackArrow = true
}) => {
  return (
    <header className="fixed top-0 inset-x-0 bg-gray-900/90 backdrop-blur-sm border-b border-gray-800/50 z-50">
      <div className="container mx-auto px-4 h-16 flex items-center gap-4">
        {showBackArrow && backUrl && (
          <Link 
            to={backUrl}
            className="flex items-center gap-2 text-gray-300 hover:text-white"
          >
            <ArrowLeft className="w-5 h-5" />
            <span className="sr-only md:not-sr-only">{backLabel}</span>
          </Link>
        )}

        <div className="flex-1 truncate">
          <h1 className="text-lg font-medium truncate">{title}</h1>
          {subtitle && (
            <p className="text-sm text-gray-400 truncate">{subtitle}</p>
          )}
        </div>

        {rightElements && (
          <div className="flex items-center gap-2">
            {rightElements}
          </div>
        )}
      </div>
    </header>
  );
};