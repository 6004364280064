// src/components/comic/ComicCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';

export const ComicCard = ({ comic, className }) => (
  <Link 
    to={`/comic/${comic.slug}`}
    className={`group block ${className}`}
  >
    <div className="relative aspect-[1/1] bg-gray-800 rounded-lg overflow-hidden">
      <img
        src={`/comics/${comic.slug}/thumbnail.jpg`}
        alt={comic.title}
        className="w-full h-full object-cover transition-transform"
      />
    </div>
    <div className="mt-2">
      <h2 className="font-medium text-lg">{comic.title}</h2>
      <p className="text-gray-400 text-sm">{comic.author}</p>
    </div>
  </Link>
);