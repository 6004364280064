// src/components/reader/PageReader.jsx
import React, { useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export const PageReader = ({ images = [], onPageChange }) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  // Reset current page when images array changes
  React.useEffect(() => {
    setCurrentPage(0);
  }, [images.length]);

  const handlePageChange = useCallback((newPage) => {
    if (newPage >= 0 && newPage < images.length) {
      setCurrentPage(newPage);
      onPageChange?.(newPage);
    }
  }, [images.length, onPageChange]);

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowLeft') handlePageChange(currentPage - 1);
      if (e.key === 'ArrowRight') handlePageChange(currentPage + 1);
    };
    
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentPage, handlePageChange]);

  // If no images, show loading state
  if (!images.length) {
    return (
      <div className="relative max-w-5xl mx-auto h-[calc(100vh-8rem)] flex items-center justify-center">
        <div className="text-gray-400">Loading...</div>
      </div>
    );
  }

  const currentImage = images[currentPage];

  // Safeguard against undefined currentImage
  if (!currentImage) {
    return (
      <div className="relative max-w-5xl mx-auto h-[calc(100vh-8rem)] flex items-center justify-center">
        <div className="text-red-400">Error loading image</div>
      </div>
    );
  }

  return (
    <div className="relative max-w-5xl mx-auto h-[calc(100vh-8rem)]">
      <div className="absolute inset-0 flex items-center justify-center">
        {currentImage.status === 'pending' || currentImage.status === 'loading' ? (
          <div className="text-gray-400">Loading...</div>
        ) : currentImage.status === 'error' ? (
          <div className="text-red-400">Failed to load image</div>
        ) : (
          <img
            src={currentImage.src}
            alt={`Page ${currentPage + 1}`}
            className="max-w-full max-h-full object-contain"
          />
        )}
      </div>

      {/* Navigation Controls */}
      <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 flex justify-between pointer-events-none">
        {currentPage > 0 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="p-2 bg-gray-800/80 rounded-r-lg pointer-events-auto 
                     hover:bg-gray-700/80 transition-colors"
          >
            <ChevronLeft className="w-8 h-8" />
          </button>
        )}
        {currentPage < images.length - 1 && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="p-2 bg-gray-800/80 rounded-l-lg pointer-events-auto 
                     hover:bg-gray-700/80 transition-colors ml-auto"
          >
            <ChevronRight className="w-8 h-8" />
          </button>
        )}
      </div>

      {/* Page Counter */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2">
        <div className="px-3 py-1 bg-gray-800/80 rounded-full text-sm">
          {currentPage + 1} / {images.length}
        </div>
      </div>
    </div>
  );
};