// src/components/reader/ReaderFooter.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export const ReaderFooter = ({ comic, currentChapter, previousChapter, nextChapter }) => (
  <footer className="fixed bottom-0 inset-x-0 bg-gray-800/50 backdrop-blur-sm z-50">
    <div className="container mx-auto px-4 h-16 flex items-center justify-between">
      {previousChapter ? (
        <Link
          to={`/comic/${comic.slug}/chapter/${previousChapter}`}
          className="flex items-center gap-2 text-gray-300 hover:text-white"
        >
          <ChevronLeft className="w-5 h-5" />
          Previous Chapter
        </Link>
      ) : (
        <div /> // Empty div for spacing
      )}

      {nextChapter && (
        <Link
          to={`/comic/${comic.slug}/chapter/${nextChapter}`}
          className="flex items-center gap-2 text-gray-300 hover:text-white"
        >
          Next Chapter
          <ChevronRight className="w-5 h-5" />
        </Link>
      )}
    </div>
  </footer>
);