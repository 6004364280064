// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { ChapterReader } from './pages/ChapterReader';
import ComicDetail from './pages/ComicDetail';

export default function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gray-900 text-gray-100">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/comic/:slug" element={<ComicDetail />} />
          <Route path="/comic/:slug/chapter/:chapterId" element={<ChapterReader />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}